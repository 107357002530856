* {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
}
body {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	position: relative;
	/* width: 100vw; */
	min-height: 100%;
	background-image: linear-gradient(
		rgba(249, 169, 134, 255),
		rgba(222, 166, 151, 255),
		rgba(202, 167, 161, 255),
		rgba(107, 147, 172, 255)
	);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
}

h3 {
	font-size: 20px;
}

#about-title,
#section-title {
	background-color: rgb(207, 207, 207);
	color: rgb(87, 87, 87);
	font-size: 40px;
	border-radius: 10px;
	box-shadow: 0 2px 5px black;
	padding: 10px;
}

.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	box-shadow: 0 1px 5px rgb(126, 126, 126);
	background-image: linear-gradient(rgba(96, 117, 136), rgba(132, 168, 201));
}

.title-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
	/* margin-left: 20px; */
	padding-left: 20px;
}
.title-section h1 {
	display: flex;
	padding: 20px;
	font-size: 40px;
	font-weight: 700;
	text-shadow: 2px 2px #000000;
	letter-spacing: 2pt;
	color: rgb(187, 187, 187);
	flex: 1 0 50%;
	padding: 10px;
}

.title-info h2 {
	padding: 10px;
	font-size: 17px;
	font-weight: 100;
	color: #3b3535;
}

.title-info span {
	color: black;
	font-weight: 900;
}

header nav {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	flex: 1 0 auto;
}

.nav-link {
	text-decoration: none !important;
	color: #131414 !important;
	font-size: 35px !important;
	font-weight: bolder !important;
	padding: 5px !important;
}

.nav-link:hover {
	text-decoration: underline !important;
	color: #247474 !important;
}
header nav ul {
	display: flex;
	padding: 5px;
}
header nav ul a {
	text-decoration: none;
	color: #131414;
	font-size: 35px;
	font-weight: bolder;
	padding: 5px;
}
header nav ul a:hover {
	text-decoration: underline;
	color: #247474;
}

main {
	width: 100%;
	display: block;
	position: relative;
}

.about-section {
	position: relative;
	display: block;
	float: left;
	width: 80%;
	margin: 0% 10% 5% 10%;
	border-radius: 25px;
	margin-bottom: 60px;
}

.about-flex {
	display: flex;
	flex-direction: row;
	flex: 1 0 50%;
	justify-content: center;
	align-content: center;
}
#about-title {
	width: 40%;
	margin-left: auto;
	margin-right: auto;
	margin-top: -20px;
	text-align: center;
}

.about-me {
	display: flex;
	/* position: relative; */
	flex-direction: column;
	height: 100%;
	flex: 1 0 30%;
	padding: 20px;
	justify-content: space-around;
	align-content: center;
}

.about-me h2 {
	/* position: relative; */
	color: rgb(87, 87, 87);
	font-size: 30px;
	padding: 10px;
	text-align: left;
}

.about-div {
	position: relative;
	display: flex;
	padding: 15px;
	background-color: rgb(207, 207, 207);
	border-radius: 10px;
}

.about-div ul li {
	margin-left: 15px;
	font-size: 20px;
	padding: 5px;
	list-style-type: circle;
}

.about-div p {
	margin-right: 20px;
	font-size: 20px;
}
.my-photo {
	position: relative;

	display: block;
	width: 30%;
	height: 100%;
	margin: 20px 20px 20px 20px;
	padding: 25px;
	border-radius: 25px;
	box-shadow: 0 2px 5px black;
	background-image: linear-gradient(rgba(141, 141, 141, 0.685), rgba(255, 255, 255, 0.685));
}

.my-photo h2 {
	font-size: 25px;
}
.my-photo img {
	position: relative;
	border-radius: 10px;
	width: 100%;
}

.app-section {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	width: 100%;
}

#section-title {
	position: relative;
	width: 35%;
	margin-left: auto;
	margin-right: auto;
	margin-top: -20px;
	text-align: center;
}

.app-container {
	padding: 2%;
	width: 60vw;
	margin: 20px;
	flex-direction: row;
	background-image: linear-gradient(rgb(141, 141, 141), rgb(255, 255, 255));
	box-shadow: 0 1px 5px rgb(126, 126, 126);
	justify-content: center;
	border-radius: 25px;
	margin-bottom: 80px;
}

.app-controls {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.app-controls button {
	width: 100px;
	border-radius: 5px;
	margin: 5px;
	border: 1px solid black;
}

.app-title {
	text-align: center;
	font-size: 4vw;
	background: -webkit-linear-gradient(rgb(250, 250, 250), rgb(192, 192, 192));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.deploy {
	background-color: #c47d76;
	width: 300px;
	height: 15%;
	text-align: center;
	border-radius: 10px;
	border: 2px #97625e solid;
	padding: 5px;
}

.repo {
	background-color: #0e77a8;
	width: 300px;
	height: 15%;
	margin-bottom: 10px;
	text-align: center;
	border-radius: 10px;
	border: 2px rgb(24, 73, 95) solid;
	padding: 5px;
}

.deploy a,
.repo a {
	color: white;
	margin-top: 50%;
	font-size: 20px;
	text-decoration: none;
}
.app-container img {
	display: flex;
	height: 40%;
	width: 40%;
	max-height: 350px;
	max-width: 350px;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 25px;
}

.app-info {
	display: flex;
	font-size: 20px;
	justify-content: center;
	text-align: justify;
}

.app-links {
	display: flex;
	margin: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-section {
	width: 100vw;
	margin-top: 7%;
	margin-bottom: 7%;
	display: block;
	position: relative;
	float: left;
}

.contact-row {
	margin: 5% 5% 5% 5%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 1 0 33%;
}

.contact-me h1 {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 60px;
	font-style: italic;
	background: -webkit-linear-gradient(rgb(250, 250, 250), rgb(184, 184, 184));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.link-div {
	border-radius: 100px;
	display: flex;
	flex-direction: row;
	margin: 20px;
	transform: scale(1);
	transition: all 0.75s ease;
}

.link-div:hover {
	transform: scale(1.1);
}
.link-div img {
	position: relative;
	display: block;
	float: left;
	height: 100px;
	padding: 5px;
	flex-basis: 50px;
}

.link-div h3 {
	position: relative;
	padding-left: 10px;
	padding-right: 20px;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex: auto;
}

.link-div h3 a {
	font-size: 1.5rem;
	text-decoration: none;
	color: white;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 200;
}

#linkedin {
	background-color: #0e77a8;
}

#gmail {
	background-color: #ea4335;
}

#github {
	background-color: #4078c0;
}

footer {
	display: block;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	background-color: white;
	background-image: linear-gradient(rgba(132, 168, 201), rgba(96, 117, 136));
}

footer p {
	padding: 10px;
	position: relative;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 50%;
}

@media screen and (max-width: 992px) {
	.about-flex {
		flex-direction: column;
	}

	#about-title,
	#section-title {
		width: 50%;
	}

	.my-photo {
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}

	.contact-row {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}

	.app-container {
		width: 80%;
	}
	.app-links {
		font-size: 20px;
	}
	.link-div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@media screen and (max-width: 768px) {
	.contact-me h1 {
		font-size: 40px;
	}
	.nav-link {
		font-size: 25px !important;
	}
	header nav ul a {
		font-size: 25px;
	}

	#first {
		flex: 1;
	}

	#first-row {
		width: 40%;
	}

	#about-title,
	#section-title {
		width: 60%;
		font-size: 30px;
	}
	.about-section {
		margin-top: 40px;
	}
	.my-photo {
		width: 80%;
	}

	.my-photo h2 {
		font-size: 17px;
	}

	.app-info {
		flex-direction: column-reverse;
	}
	.app-info img {
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (max-width: 576px) {
	.about-section,
	.app-section,
	.contact-section {
		margin-bottom: 80px;
	}
	header {
		flex-direction: column;
	}

	header nav {
		justify-content: space-evenly;
	}

	.nav-link {
		font-size: 15px !important;
	}
	header nav ul a {
		font-size: 15px;
	}

	.title-section h1 {
		font-size: 30px;
	}

	.app-title {
		font-size: 20px;
	}
	.description {
		font-size: 15px;
	}
	.deploy,
	.repo {
		width: 150px;
	}
	.about-section {
		margin-left: 10px;
		margin-right: 10px;
		width: 95%;
	}

	.about-div ul li,
	.about-div p {
		font-size: 17px;
	}
	.my-photo {
		width: 80%;
	}

	.my-photo h2 {
		font-size: 17px;
	}

	.contact-row {
		width: 75%;
	}

	.contact-me h1 {
		font-size: 25px;
	}
	footer {
		height: 70px;
	}

	.link-div h3 a {
		font-size: 20px;
	}
	.link-div img {
		height: 50px;
	}
}
